<template>
  <div>
     <v-card class="mb-3">
        <v-card-title class="py-0">
          <v-icon class="mr-2">{{ico}}</v-icon> 
            {{name}}
          <v-spacer></v-spacer>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            label="Поиск"
            hide-details
            class="mb-3"
            v-model="search"
            @input="inputSearch"
          ></v-text-field>
         </v-card-title>
      </v-card>
  </div>
</template>

<script>
export default {
  name: "HeadCard",
  props: {
    ico: String,
    name: String,
  },
  data () {
    return {
     search:"",
    }
  },

  methods:{
    inputSearch()
    {
      this.$emit('inputSearch', this.search)
    }
  }
}
</script>

<style scoped>

</style>